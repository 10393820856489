import React from "react";
import useMediaQuery from "@mui/material/useMediaQuery";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import useTheme from "@mui/material/styles/useTheme";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import Box from "@mui/material/Box";
import {height as cardHeight} from "./ParcelleCard";
import GoogleMapsButton from "./GoogleMapsButton";
import GoogleStreetViewButton from "./GoogleStreetViewButton";

const ParcelleRegroupeeCard = React.forwardRef(({
                                                  reference_cadastrale,
                                                  adresse,
                                                  selected,
                                                  longitude,
                                                  latitude,
                                                  commune,
                                                  surface,
                                                  onClick,
                                                  onDelete,
                                                  sx,
                                                  children
                                                }, ref) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  return <Box
    sx={{padding: "0 15px"}}
    ref={ref}
  >
    <Paper
      elevation={1}

      onClick={() => {
        onClick()
      }}
      sx={{
        position: "relative",
        display: "flex",
        height: cardHeight + "em",
        borderRadius: "20px",
        alignItems: "center",
        flexDirection: "row",
        backgroundColor: selected ? "tertiary.dark" : "secondary.main",
        color: selected ? "primary.contrastText" : "secondary.contrastText",
        padding: "15px 50px 15px 20px",
        gridTemplateColumns: "1fr 50px",
        minWidth: "auto",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: selected ? "tertiary.dark" : "secondary.dark",
        },
        "&:active": {
          backgroundColor: selected ? "tertiary.dark" : "secondary.light",
        },
        ...sx
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          height: "90px",
          borderRadius: "20px",
          alignItems: "flex-start",
          flexDirection: "column",
          width: "120px"
        }}>
        <Box>
          <Typography sx={{
            flex: 1,
            alignSelf: "stretch",
            fontSize: "120%",
            padding: isMobile ? "10px 0 0 0" : "null",
          }}>
            {children.length} Parcelles
          </Typography>

          <Typography sx={{
            flex: 1,
            alignSelf: "stretch",
            fontSize: "140%",
          }}>
            {surface} m²
          </Typography>
        </Box>
      </Box>
      {children}

      {selected && <>
        <Button
          onClick={() => {
            onDelete()
          }}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "primary.contrastText",
            height: "28px",
            borderRadius: "20px",
            minWidth: "28px"
          }}
        >
          <FontAwesomeIcon icon={faXmark}/>
        </Button>

        <GoogleMapsButton
          sx={{
            position: "absolute",
            bottom: isMobile ? "44px" : "42px",
            right: "10px",
          }}
          latitude={latitude}
          longitude={longitude}
        />
        <GoogleStreetViewButton
          sx={{
            position: "absolute",
            bottom: "10px",
            right: "10px"
          }}
          latitude={latitude}
          longitude={longitude}
        />
      </>}
    </Paper>
  </Box>;
})

export default ParcelleRegroupeeCard;
