import React, {useState} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Typography from "@mui/material/Typography";
import {debounce} from "@mui/material/utils";
import axios from "axios";
import {communes} from "./communes";
import Stack from "@mui/material/Stack";


const CommuneSelect = ({
                         onSelectCommune,
                         distanceKmAutourCommune,
                         onSelectDistanceKmAutourCommune,
                         fieldProps,
                         size = "normal",
                         hideDistance = false,
                       }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const getRandomCommune = (escapeValue) => {
    const result = communes[Math.floor(Math.random() * communes.length)].name.toUpperCase();
    if (result === escapeValue) {
      return getRandomCommune(escapeValue);
    }
    return result;
  };

  const handleSliderChange = (event, newValue) => {
    onSelectDistanceKmAutourCommune(newValue);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const PopupOpen = Boolean(anchorEl);

  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [placeholder, setPlaceholder] = useState("");
  const [fullPlaceholder, setFullPlaceholder] = useState("");
  const [charIndex, setCharIndex] = useState(0);
  const [open, setOpen] = useState(false);

  React.useEffect(() => {
    let interval;
    const timeout = setTimeout(() => {
      const newCommune = getRandomCommune(fullPlaceholder);
      setFullPlaceholder(newCommune);
      setPlaceholder("");
      setCharIndex(0);
      interval = setInterval(() => {
        const newCommune = getRandomCommune(fullPlaceholder);
        setFullPlaceholder(newCommune);
        setPlaceholder("");
        setCharIndex(0);
      }, 4000);
    }, 100);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    } // Cleanup interval on unmount
  }, []);

  React.useEffect(() => {
    if (charIndex < fullPlaceholder.length) {
      const timeout = setTimeout(() => {
        setPlaceholder((prev) => prev + fullPlaceholder[charIndex]);
        setCharIndex(charIndex + 1);
      }, 80); // Adjust typing speed here

      return () => clearTimeout(timeout); // Cleanup timeout on unmount or when charIndex changes
    }
  }, [charIndex, fullPlaceholder]);

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        axios.get("/communes", {
          params: {
            recherche: request.input
          }
        }).then(function(response) {
          const responseData = response.data;
          callback(responseData);
        }).catch(function(error) {
          console.log(error);
        })
      }, 400),
    [],
  );

  React.useEffect(() => {
    let active = true;
    setOpen(false);

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }

    fetch({input: inputValue}, (results) => {
      if (active) {
        setOpen(true);
        setOptions(results);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue]);

  return (
    <>
      <Autocomplete
        {...fieldProps}
        open={open}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions={false}
        isOptionEqualToValue={(option, value) => {
          return option.id_commune === value.id_commune;
        }}
        filterOptions={(options, state) => options}
        value={value}
        onBlur={() => setOpen(false)}
        disableClearable
        openOnFocus={false}
        noOptionsText="Aucun résultat"
        onChange={(event, newValue) => {
          setValue(newValue);
          onSelectCommune(newValue);
          setOpen(false);
        }}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            setInputValue(newInputValue);
          }
        }}
        getOptionLabel={(option) => option.nom + " (" + option.code_postal + ")"}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box sx={{
                  display: "flex",
                  width: 44
                }}>
                  <LocationOnIcon sx={{color: "text.primary"}}/>
                </Box>
                <Box
                  sx={{
                    width: "calc(100% - 44px)",
                    wordWrap: "break-word"
                  }}>
                  <Box
                    key={option.nom + "-" + option.code_postal}
                    component="span"
                    sx={{fontWeight: "bold"}}
                  >
                    {option.nom}
                  </Box>
                  <Typography variant="body2" color="text.primary">
                    {option.code_postal}
                  </Typography>
                </Box>
              </Stack>
            </li>
          );
        }}

        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={placeholder}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "50px",
              },
              "& .MuiAutocomplete-inputRoot": {
                padding: (size === "small" ? "5px 20px 5px 20px" : "9px 30px 9px 30px"),
              },
              width: hideDistance ? "80%" : "100%",
            }}
            fullWidth
            slotProps={{
              input: {
                ...params.InputProps,
                autoCorrect: "off",
                autoComplete: "off",
                spellCheck: "false",
                autoCapitalize: "none",
                inputMode: "text",
                sx: {
                  fontSize: (size === "small" ? "80%" : "100%"),
                },
                endAdornment: (!hideDistance && (
                  <InputAdornment position="end">
                    <Button color="primary" disableElevation onClick={handleClick} sx={{
                      width: "100px",
                      fontSize: "80%",
                    }}>
                      {distanceKmAutourCommune} km autour
                    </Button>
                  </InputAdornment>
                )),
              }
            }}
          />
        )}
      />
      <Popover
        open={PopupOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{
          padding: "3px 20px 1px 20px"
        }}>
          <Slider
            value={distanceKmAutourCommune}
            onChange={handleSliderChange}
            aria-labelledby="distance-slider"
            valueLabelDisplay="off"
            min={0}
            max={10}
            color="primary"
            sx={{
              width: "100px",
              "& .MuiSlider-thumb": {
                "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                  boxShadow: "inherit",
                },
                "&::before": {
                  display: "none",
                },
              },
            }}
          />
        </Box>
      </Popover>
    </>
  );
};

export default CommuneSelect;
