import React, {createContext, useContext, useEffect, useState} from "react";
import axios from "axios";
import GeoJSON from "ol/format/GeoJSON";
import {AppContext} from "./AppProvider";
import {MapContext} from "./MapProvider";


export const SearchContext = createContext();

export const TYPE_RECHERCHE_SURFACE = "SURFACE";
export const TYPE_RECHERCHE_ADRESSE = "ADRESSE";
export const TYPE_RECHERCHE_REFERENCECADASTRALE = "REFERENCECADASTRALE";


const SearchProvider = ({children}) => {
  const [typeRecherche, setTypeRecherche] = useState(TYPE_RECHERCHE_SURFACE);

  //Recherche par surface
  const [idCommune, setIdCommune] = useState(null);
  const [distanceKmAutourCommune, setDistanceKmAutourCommune] = useState(0);
  const [surface, setSurface] = useState("");
  const [toleranceSurfacePourcentage, setToleranceSurfacePourcentage] = useState(0);
  const [tenterRegrouperParcelles, setTenterRegrouperParcelles] = useState(false);

  //Recherche par adresse
  const [idAdresse, setIdAdresse] = useState(null);
  const [distanceMAutourAdresse, setDistanceMAutourAdresse] = useState(10);

  //Recherche par reference cadastrale
  //idcommune
  const [idParcelle, setIdParcelle] = useState(null);

  const {
    setLoading,
    openResultPanel,
    getWaitTokenAndWait,
    setAlertMessage
  } = useContext(AppContext);

  const {
    setFeatures,
    setSelectedFeature,
  } = useContext(MapContext);

  useEffect(() => {
    setIdCommune(null); //7353
    setDistanceKmAutourCommune(0);
    setSurface("");
    setToleranceSurfacePourcentage(0);
    setTenterRegrouperParcelles(false);
    setIdAdresse(null);
    setDistanceMAutourAdresse(10);
    setIdParcelle(null);
  }, [typeRecherche]);

  const formIsValidOrGetErrors = () => {
    if (typeRecherche === TYPE_RECHERCHE_SURFACE) {
      if (idCommune === null) {
        return "Veuillez sélectionnez une commune dans la liste";
      } else if (surface <= 0) {
        return "Veuillez saisir une surface supérieure à zéro";
      } else if (surface > 100000) {
        return "Veuillez saisir une surface inférieure à 100000";
      }
      return true;
    } else if (typeRecherche === TYPE_RECHERCHE_ADRESSE) {
      if (idAdresse === null) {
        return "Veuillez sélectionnez une adresse dans la liste";
      }
      return true;
    } else if (typeRecherche === TYPE_RECHERCHE_REFERENCECADASTRALE) {
      if (idParcelle === null) {
        return "Veuillez sélectionnez une parcelle dans la liste";
      }
      return true;
    }
    return false;
  }

  const doSearch = async () => {
    if (formIsValidOrGetErrors() !== true) {
      setAlertMessage(formIsValidOrGetErrors());
      setTimeout(() => {
        setAlertMessage(null);
      }, 5000);
      return;
    }
    if (typeof umami != "undefined") {
      umami.track("Lancement recherche");
    }
    setLoading(true);

    try {
      const waitToken = await getWaitTokenAndWait();

      let response;
      if (typeRecherche === TYPE_RECHERCHE_SURFACE) {
        response = await axios.get("/parcelles_by_commune", {
          params: {
            wait_token: waitToken,
            id_commune: idCommune,
            distance_km_autour_commune: distanceKmAutourCommune,
            surface: surface,
            tolerance_surface_pourcentage: toleranceSurfacePourcentage,
            tenter_regrouper_parcelles: tenterRegrouperParcelles
          }
        });
      } else if (typeRecherche === TYPE_RECHERCHE_ADRESSE) {
        response = await axios.get("/parcelles_by_adresse", {
          params: {
            wait_token: waitToken,
            id_adresse: idAdresse,
            distance_m_autour_adresse: distanceMAutourAdresse
          }
        });
      } else if (typeRecherche === TYPE_RECHERCHE_REFERENCECADASTRALE) {
        response = await axios.get("/parcelle", {
          params: {
            wait_token: waitToken,
            id_parcelle: idParcelle,
          }
        });
      }
      const responseData = response.data;
      const data = JSON.parse(responseData[0].data) || [];

      let allFeatures = [];

      // Parcourir tous les éléments du tableau de données
      data.forEach(item => {
        const feature = new GeoJSON().readFeature(item.geom, {
          featureProjection: "EPSG:3857", // Projection de la carte
        });
        if (item.is_regroupee) {
          let parcellesFilles = [];
          item.parcelles.forEach(parcelle => {
            const subFeature = new GeoJSON().readFeature(parcelle.geom, {
              featureProjection: "EPSG:3857", // Projection de la carte
            });
            subFeature.setProperties(parcelle);
            parcellesFilles.push(subFeature);
          });
          item.parcelles = parcellesFilles;
        }
        feature.setProperties(item);

        allFeatures.push(feature);
      });

      setFeatures(allFeatures);
      if (allFeatures.length === 1) {
        setSelectedFeature(allFeatures[0]);
      }
      setLoading(false);
      openResultPanel(true);
    } catch(error) {
      setAlertMessage("Une erreur s'est produite. Votre recherche a peut-être générée trop de résultat. Essayez de limiter votre recherche.");
      setTimeout(() => {
        setAlertMessage(null);
      }, 5000);
      setLoading(false);
      if (typeof umami != "undefined") {
        umami.track("Erreur recherche");
      }
      console.error("Error fetching data:", error);
    }
  };

  return <SearchContext.Provider value={{
    typeRecherche,
    setTypeRecherche,
    idCommune,
    setIdCommune,
    distanceKmAutourCommune,
    setDistanceKmAutourCommune,
    surface,
    setSurface,
    toleranceSurfacePourcentage,
    setToleranceSurfacePourcentage,
    tenterRegrouperParcelles,
    setTenterRegrouperParcelles,
    idAdresse,
    setIdAdresse,
    distanceMAutourAdresse,
    setDistanceMAutourAdresse,
    idParcelle,
    setIdParcelle,
    doSearch,
    formIsValidOrGetErrors
  }}>
    {children}
  </SearchContext.Provider>
};

export default SearchProvider;
