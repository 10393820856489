import React, {useContext} from "react";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useTheme} from "@mui/material/styles";
import ButtonGroup from "@mui/material/ButtonGroup";
import CircularProgress from "@mui/material/CircularProgress";
import {SearchContext, TYPE_RECHERCHE_ADRESSE, TYPE_RECHERCHE_REFERENCECADASTRALE, TYPE_RECHERCHE_SURFACE} from "./context/SearchProvider";
import {AppContext} from "./context/AppProvider";
import SearchPanel from "./common/SearchPanel";
import {Adsense} from "@ctrl/react-adsense";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{
      position: "relative",
      display: "inline-flex"
    }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{color: "text.secondary"}}
        >
          {props.text}
        </Typography>
      </Box>
    </Box>
  );
}

const SearchPopup = () => {
  const theme = useTheme();

  const {
    typeRecherche,
    setTypeRecherche,
    doSearch,
    formIsValidOrGetErrors
  } = useContext(SearchContext);

  const {
    loading,
    setMentionsLegalesOpen,
    needToWaitUntil,
    progressToWait
  } = useContext(AppContext)

  return <>
    <Paper
      elevation={0}
      sx={{
        backgroundColor: theme.palette.background.paper,
        display: "flex",
        width: "650px",
        height: "450px",
        borderRadius: "30px",
        alignItems: "center",
        position: "absolute",
        top: "calc(50% - 22.5px)",
        left: "50%",
        padding: "env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left)",
        transform: "translate(-50%, -50%)",
        overflow: "hidden",
        [theme.breakpoints.down("sm")]: {
          width: "100%",
          height: "100%",
          borderRadius: "0px",
          flexDirection: "column",
          overflowY: "auto",
        },
      }}
    >
      {!loading && <>
        <Box sx={{
          position: "relative",
          width: "35%",
          height: "100%",
          marginLeft: "20px",
          [theme.breakpoints.down("sm")]: {
            height: "30%",
            marginLeft: "0px",
          },
        }}
        >
          <Box
            className="mask-pin"
            sx={{
              position: "absolute",
              width: "100%",
              height: "150px",
              top: "25%",
              border: "none !important",
              backgroundColor: theme.palette.primary.light,
              zIndex: 2,
              maskSize: "80%",
              maskPosition: "70% 50%",
              maskRepeat: "no-repeat",
              [theme.breakpoints.down("sm")]: {
                height: "120px",
                width: "100%",
              }
            }}
          />
          <Box
            className="mask-town"
            sx={{
              position: "absolute",
              width: "100%",
              height: "150px",
              top: "40%",
              border: "none !important",
              transform: "rotate3d(200, 0, 0, 60deg) scale(150%)",
              transformOrigin: "center",
              background: theme.palette.mode === "light" ? "radial-gradient(circle, rgb(105,118,124,20%) 0%, rgb(105,118,124,0%) 50%)" : "radial-gradient(circle, rgb(105,118,124,40%) 0%, rgb(105,118,124,0%) 50%)",
              maskSize: "100%",
              maskPosition: "50% 50%",
              maskRepeat: "no-repeat",
              [theme.breakpoints.down("sm")]: {
                height: "120px",
                width: "100%",
              }
            }}
          />
        </Box>
        <Box
          sx={{
            display: "grid",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "flex-start",
            width: "100%",
          }}
        >
          <Typography sx={{
            flex: 1,
            alignSelf: "stretch",
            padding: "20px 40px 0px 40px",
            [theme.breakpoints.down("sm")]: {
              padding: "10px 20px 0px 20px",
              fontSize: "155%"
            }
          }} variant="h1">
            Recherchez une parcelle
          </Typography>

          <ButtonGroup
            disableElevation
            sx={{
              flex: 1,
              alignSelf: "stretch",
              justifySelf: "center",
              padding: "10px 40px 10px 40px",
              [theme.breakpoints.down("sm")]: {
                padding: "10px 20px 0px 20px",
              }
            }}
            size="small" variant="text" aria-label="Basic button group">
            <Button
              sx={{height: "18px"}}
              onClick={() => setTypeRecherche(TYPE_RECHERCHE_SURFACE)}
              color={typeRecherche === TYPE_RECHERCHE_SURFACE ? "primary" : "secondary"}
            >
              {useMediaQuery(theme.breakpoints.down("sm")) ? "Surface" : "Par surface"}
            </Button>
            <Button
              sx={{height: "18px"}}
              onClick={() => setTypeRecherche(TYPE_RECHERCHE_ADRESSE)}
              color={typeRecherche === TYPE_RECHERCHE_ADRESSE ? "primary" : "secondary"}
            >
              {useMediaQuery(theme.breakpoints.down("sm")) ? "Adresse" : "Par adresse"}
            </Button>
            <Button
              sx={{height: "18px"}}
              onClick={() => setTypeRecherche(TYPE_RECHERCHE_REFERENCECADASTRALE)}
              color={typeRecherche === TYPE_RECHERCHE_REFERENCECADASTRALE ? "primary" : "secondary"}
            >
              {useMediaQuery(theme.breakpoints.down("sm")) ? "Références cadastrales" : "Par références cadastrales"}
            </Button>
          </ButtonGroup>

          <SearchPanel
            hideTenterRegrouperParcelles={false}
          />

          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              alignSelf: "stretch",
              justifyContent: "flex-end",
              padding: "20px 40px 10px 40px",
              [theme.breakpoints.down("sm")]: {
                padding: "10px 20px",
              }
            }}>
            <Box sx={{
              flex: 1,
              alignSelf: "center",
              fontSize: "70%",
              [theme.breakpoints.down("sm")]: {
                padding: "0px 30px 0 0"
              }
            }}>
              En cliquant sur "Rechercher", vous acceptez les <Box onClick={() => setMentionsLegalesOpen(true)}
                                                                   sx={{
                                                                     cursor: "pointer",
                                                                     fontSize: "100%",
                                                                     textDecoration: "underline",
                                                                     textDecorationColor: theme.palette.primary.main,
                                                                     display: "inline",
                                                                     "&:hover": {
                                                                       color: theme.palette.primary.main,
                                                                     }
                                                                   }}>CGU</Box>
            </Box>
            <Button disabled={formIsValidOrGetErrors() !== true} onClick={doSearch} variant="contained" disableElevation color="secondary">
              Rechercher
            </Button>
          </Box>
        </Box>
      </>}
      {loading && <>
        <Box sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          alignItems: "center",
          padding: "20px"
        }}>
          <Box sx={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px 0"
          }}>
            <CircularProgressWithLabel
              sx={{margin: "0 10px"}}
              text={needToWaitUntil}
              variant={progressToWait === null ? "indeterminate" : "determinate"}
              value={progressToWait}
            />
          </Box>

          <Box sx={{
            display: "flex",
            flexDirection: "column",
            width: "80%",
            flex: 0.8,
            padding: "20px",
            backgroundColor: "#999999",
            textAlign: "center"
          }}>
            <Adsense
              client="ca-pub-7622919582571565"
              slot="7090252680"
              style={{display: "block"}}
              format="auto"
              responsive="true"
            />
          </Box>
        </Box>
      </>}
    </Paper>
  </>
    ;
}

export default SearchPopup;
