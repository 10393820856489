import React, {useState} from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import Slider from "@mui/material/Slider";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";


const SurfaceField = ({
                        surface,
                        onSetSurface,
                        toleranceSurfacePourcentage,
                        onSelectToleranceSurfacePourcentage,
                        fieldProps,
                        size = "normal"
                      }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleSliderChange = (event, newValue) => {
    onSelectToleranceSurfacePourcentage(Math.round(newValue) / 100);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <TextField
        {...fieldProps}
        type="number"
        fullWidth
        sx={{
          ...fieldProps.sx,
          "& input[type=number]": {
            fontSize: (size === "small" ? "80%" : "100%"),
            padding: (size === "small" ? "5px 20px 5px 20px" : "16.5px 14px"),
          },
          "& input::-webkit-outer-spin-button": {
            margin: 0,
          },
          "& input::-webkit-inner-spin-button": {
            margin: 0,
          },
          "& .MuiOutlinedInput-root": {
            borderRadius: "50px",
          },
          "& .MuiAutocomplete-inputRoot": {
            padding: (size === "small" ? "5px 20px 5px 20px" : "9px 30px 9px 30px"),
          }
        }}
        value={surface}
        onChange={(event) => {
          let value = event.target.value;

          // Supprimer tous les caractères non numériques
          value = value.replace(/[^0-9]/g, "");

          // Mettre à jour l'état avec la valeur purgée
          onSetSurface(value);
        }}
        onKeyDown={(event) => {
          const isControlKey = event.ctrlKey || event.metaKey;

          if (
            event.key.length === 1 && // Exclut les touches de contrôle (Backspace, Tab, etc.)
            !/[0-9]/.test(event.key) && // N'autorise que les chiffres
            !isControlKey // Permet les combinaisons comme Ctrl+V, Cmd+C, etc.
          ) {
            event.preventDefault();
          }
        }}
        onPaste={(event) => {
          const pastedData = event.clipboardData.getData('text');

          if (!/^\d+$/.test(pastedData)) {
            event.preventDefault(); // Empêche le collage si ce n'est pas un nombre
          }
        }}
        placeholder="800"
        slotProps={{
          input: {
            sx: {
              paddingRight: "20px",
              paddingLeft: "20px"
            },
            endAdornment: (
              <InputAdornment position="end">
                <span>m²</span>
                <Button color="primary" disableElevation onClick={handleClick} sx={{
                  width: "100px",
                  fontSize: "80%",
                }}>
                  +/- {Math.round(toleranceSurfacePourcentage * 100)}%
                </Button>
              </InputAdornment>
            )
          }
        }}
      />
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        elevation={2}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{
          padding: "3px 20px 1px 20px"
        }}>
          <Slider
            value={Math.round(toleranceSurfacePourcentage * 100)}
            onChange={handleSliderChange}
            aria-labelledby="distance-slider"
            valueLabelDisplay="off"
            min={0}
            max={10}
            color="primary"
            sx={{
              width: "100px",
              "& .MuiSlider-thumb": {
                "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
                  boxShadow: "inherit",
                },
                "&::before": {
                  display: "none",
                },
              },
            }}
          />
        </Box>
      </Popover>
    </>
  );
};

export default SurfaceField;
