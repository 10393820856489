import React from "react";
import Paper from "@mui/material/Paper";
import useMediaQuery from "@mui/material/useMediaQuery";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import {useTheme} from "@mui/material/styles";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"
import {faXmark} from "@fortawesome/free-solid-svg-icons"
import Box from "@mui/material/Box";
import GoogleStreetViewButton from "./GoogleStreetViewButton";
import GoogleMapsButton from "./GoogleMapsButton";

export const height = 7.3;

const ParcelleCard = React.forwardRef(({
                                         reference_cadastrale,
                                         adresse,
                                         selected,
                                         longitude,
                                         latitude,
                                         commune,
                                         surface,
                                         onClick,
                                         onDelete,
                                         isInRegroupement = false,
                                         sx
                                       }, ref) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));


  return <Box
    sx={{padding: isInRegroupement ? "0 5px" : "0 15px"}}
    ref={ref}
  >
    <Paper
      elevation={1}
      onClick={() => {
        onClick()
      }}
      sx={{
        position: "relative",
        display: "flex",
        height: height + "em",
        borderRadius: "20px",
        alignItems: "center",
        overflow: "hidden",
        flexDirection: "column",
        backgroundColor: selected ? "tertiary.main" : "primary.main",
        color: selected ? "primary.contrastText" : "primary.contrastText",
        padding: isInRegroupement ? "1em 1.2em" : "1em 3.2em 1em 1.2em",
        gridTemplateColumns: "1fr 50px",
        minWidth: "13em",
        maxWidth: "13em",
        cursor: "pointer",
        "&:hover": isInRegroupement ? {} : {
          backgroundColor: selected ? "tertiary.main" : "primary.dark",
        },
        "&:active": isInRegroupement ? {} : {
          backgroundColor: selected ? "tertiary.main" : "primary.light",
        },
        ...sx
      }}
    >

      {selected && !isInRegroupement && <>
        <Button
          onClick={() => {
            onDelete()
          }}
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "primary.contrastText",
            height: "28px",
            borderRadius: "20px",
            minWidth: "28px"
          }}
        >
          <FontAwesomeIcon icon={faXmark}/>
        </Button>

        <GoogleMapsButton
          sx={{
            position: "absolute",
            bottom: isMobile ? "44px" : "42px",
            right: "10px",
          }}
          latitude={latitude}
          longitude={longitude}
        />
        <GoogleStreetViewButton
          sx={{
            position: "absolute",
            bottom: "10px",
            right: "10px"
          }}
          latitude={latitude}
          longitude={longitude}
        />
      </>}
      <Typography sx={{
        flex: 1,
        alignSelf: "stretch",
        fontSize: "150%",
      }}>
        {surface} m²
      </Typography>

      <Typography sx={{
        flex: 1,
        alignSelf: "stretch",
        fontSize: "70%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }}>
        Parcelle {reference_cadastrale}
      </Typography>

      <Typography
        title={(adresse && adresse.length > 20) ? (adresse + " " + commune) : null}
        sx={{
          flex: 1,
          alignSelf: "stretch",
          fontSize: "80%",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis"
        }}>
        {adresse}
      </Typography>

      <Typography sx={{
        flex: 1,
        alignSelf: "stretch",
        fontSize: "80%",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis"
      }}>
        {commune}
      </Typography>


    </Paper>
  </Box>;
})

export default ParcelleCard;
