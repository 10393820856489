import React, {useEffect, useState} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Typography from "@mui/material/Typography";
import {debounce} from "@mui/material/utils";
import axios from "axios";
import Stack from "@mui/material/Stack";

const ParcelleSelect = ({
                          onSelectParcelle,
                          fieldProps,
                          size = "normal",
                          disabled = false,
                          idCommune
                        }) => {
  const [value, setValue] = React.useState(null);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState([]);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setInputValue("");
    setValue(null);
    onSelectParcelle(null);
  }, [idCommune]);

  const fetch = React.useMemo(
    () =>
      debounce((request, callback) => {
        axios.get("/parcelle_by_reference_cadastrale", {
          params: {
            id_commune: idCommune,
            reference_cadastrale: request.input
          }
        }).then(function(response) {
          const responseData = response.data;
          callback(responseData);
        }).catch(function(error) {
          console.log(error);
        })
      }, 400),
    [idCommune],
  );

  React.useEffect(() => {
    let active = true;
    setOpen(false);

    if (inputValue === "") {
      setOptions([]);
      return undefined;
    }

    fetch({input: inputValue}, (results) => {
      if (active) {
        setOpen(true);
        setOptions(results);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue]);

  return (
    <>
      <Autocomplete
        {...fieldProps}
        open={open}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions={false}
        isOptionEqualToValue={(option, value) => {
          return option.id_parcelle === value.id_parcelle;
        }}
        filterOptions={(options, state) => options}
        value={value}
        onBlur={() => setOpen(false)}
        disableClearable
        openOnFocus={false}
        noOptionsText="Aucun résultat"
        onChange={(event, newValue) => {
          setValue(newValue);
          onSelectParcelle(newValue);
          setOpen(false);
        }}
        onInputChange={(event, newInputValue, reason) => {
          if (reason === "input") {
            setInputValue(newInputValue);
          }
        }}
        getOptionLabel={(option) => option.reference_cadastrale || ""}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Stack direction="row" alignItems="center" spacing={2}>
                <Box sx={{
                  display: "flex",
                  width: 44
                }}>
                  <LocationOnIcon sx={{color: "text.primary"}}/>
                </Box>
                <Box sx={{
                  width: "calc(100% - 44px)",
                  wordWrap: "break-word"
                }}>
                  <Box
                    key={option.reference_cadastrale}
                    component="span"
                    sx={{fontWeight: "bold"}}
                  >
                    {option.reference_cadastrale}
                  </Box>
                  <Typography variant="body2" color="text.primary">
                    {option.adresse}
                  </Typography>
                </Box>
              </Stack>
            </li>
          );
        }}
        disabled={disabled}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Préfixe (facultatif) - Section - Numéro"
            placeholder={"000 XX 0000"}
            sx={{
              "& .MuiOutlinedInput-root": {
                borderRadius: "50px",
              },
              "& .MuiAutocomplete-inputRoot": {
                padding: (size === "small" ? "5px 20px 5px 20px" : "9px 30px 9px 30px"),
              }
            }}
            fullWidth
            slotProps={{
              input: {
                ...params.InputProps,
                autoCorrect: "off",
                autoComplete: "off",
                spellCheck: "false",
                autoCapitalize: "none",
                inputMode: "text",
                sx: {
                  fontSize: (size === "small" ? "80%" : "100%"),
                },
                endAdornment: null
              }
            }}
            disabled={disabled}
          />
        )}
      />
    </>
  );
};

export default ParcelleSelect;
