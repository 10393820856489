import React, {createContext, useState} from "react";

export const MapContext = createContext();

const MapProvider = ({children}) => {
  const [features, setFeatures] = useState([]);
  const [selectedFeature, setSelectedFeature] = useState(null);
  const [showCadastreLayer, setShowCadastreLayer] = useState(false);

  const toggleCadastreLayer = () => setShowCadastreLayer(!showCadastreLayer);

  return <MapContext.Provider value={{
    features,
    setFeatures,
    selectedFeature,
    setSelectedFeature,
    showCadastreLayer,
    toggleCadastreLayer,
  }}>
    {children}
  </MapContext.Provider>
};

export default MapProvider;
