import React, {useContext, useEffect, useRef, useState} from "react";
import View from "ol/View";
import olMap from "ol/Map";
import {fromLonLat, toLonLat} from "ol/proj";
import {getRandomCommune} from "./common/communes";
import {CommunesLayer, OrthoLayer, ParcellesLayer, SectionsLayer} from "./Map/IGNLayer";
import Box from "@mui/material/Box";
import Popover from "@mui/material/Popover";
import {MapContext} from "./context/MapProvider";
import {AppContext} from "./context/AppProvider";
import DoubleClickZoom from "ol/interaction/DoubleClickZoom";
import DragPan from "ol/interaction/DragPan";
import DragZoom from "ol/interaction/DragZoom";
import MouseWheelZoom from "ol/interaction/MouseWheelZoom";
import Select from "ol/interaction/Select";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import {parcelleRegroupeeStyle, parcelleStyle, selectedParcelleStyle} from "./Map/style";
import {lightTheme} from "./common/theme";
import Overlay from "ol/Overlay";
import GoogleMapsButton from "./ResultPanel/GoogleMapsButton";
import GoogleStreetViewButton from "./ResultPanel/GoogleStreetViewButton";
import PinchZoom from "ol/interaction/PinchZoom"; // Importez PinchZoom
import DragRotateAndZoom from "ol/interaction/DragRotateAndZoom";
import useMediaQuery from "@mui/material/useMediaQuery";
import useTheme from "@mui/material/styles/useTheme"; // Optionnel pour la rotation avec deux doigts


const Map = () => {
  const {
    features,
    selectedFeature,
    setSelectedFeature,
    showCadastreLayer
  } = useContext(MapContext);
  const {resultPanelOpen} = useContext(AppContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const selectRef = useRef(null);
  const mapRef = useRef(null);
  const mapDivRef = useRef();
  const popupRef = useRef();
  const [lonLat, setLonLat] = useState(null);
  const [anchorPosition, setAnchorPosition] = useState(null);

  useEffect(() => {
    if (mapRef.current) {
      return;
    }

    selectRef.current = new Select({
      style: selectedParcelleStyle(lightTheme),
      filter: function(feature, layer) {
        return layer.get("selectionnable");
      }
    });

    selectRef.current.on("select", (event) => {
      const selectedFeatures = event.target.getFeatures();
      setSelectedFeature(selectedFeatures.getArray()[0]);
    });

    const overlay = new Overlay({
      element: popupRef.current,
      positioning: "bottom-center",
      stopEvent: false,
      offset: [0, -10],
    });

    mapRef.current = new olMap({
      target: mapDivRef.current,
      view: new View({
        center: fromLonLat(getRandomCommune(true)),
        zoom: 16,
        projection: "EPSG:3857"
      }),
      controls: [],
      interactions: [
        new DoubleClickZoom(),
        new DragPan(),
        new DragZoom(),
        new MouseWheelZoom(),
        new PinchZoom(),
        new DragRotateAndZoom(),
        selectRef.current
      ],
      overlays: [
        overlay
      ]
    });

    mapRef.current.on("contextmenu", (event) => {
      event.preventDefault(); // Empêcher le menu contextuel par défaut

      // Obtenir les coordonnées du clic
      const coords = event.coordinate;

      // Convertir les coordonnées en LonLat
      const lonLatCoords = toLonLat(coords);

      // Obtenir la position en pixels pour le Popover
      const pixel = event.pixel;

      // Positionner la popup avec les coordonnées du clic
      setAnchorPosition({
        left: pixel[0],
        top: pixel[1],
      });

      // Mettre à jour les coordonnées LonLat
      setLonLat(lonLatCoords);
    });
    return () => {
      mapRef.current.setTarget(undefined);
      mapRef.current.dispose();
    };
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      let layers = [];
      layers.push(OrthoLayer)
      if (resultPanelOpen && showCadastreLayer) {
        layers.push(CommunesLayer)
        layers.push(SectionsLayer)
        layers.push(ParcellesLayer)
      }

      let parcelles = [];
      let parcellesRegroupees = [];
      let hasParcelleRegroupee = false;

      features.forEach(feature => {
        if (feature.getProperties().is_regroupee === true) {
          hasParcelleRegroupee = true;
          parcellesRegroupees = parcellesRegroupees.concat(feature.getProperties().parcelles)
        }
        parcelles.push(feature)
      });

      if (hasParcelleRegroupee) {
        const parcelleRegroupeesSource = new VectorSource({
          features: parcellesRegroupees
        });

        const parcellesRegroupeesLayer = new VectorLayer({
          source: parcelleRegroupeesSource,
          selectionnable: false,
          style: parcelleRegroupeeStyle(lightTheme)
        });

        layers.push(parcellesRegroupeesLayer);
      }

      const parcellesSource = new VectorSource({
        features: parcelles
      });

      const parcellesLayer = new VectorLayer({
        source: parcellesSource,
        selectionnable: true,
        style: parcelleStyle(lightTheme)
      });

      layers.push(parcellesLayer);

      mapRef.current.setLayers(layers)
    }
  }, [features, showCadastreLayer]);

  useEffect(() => {
    if (mapRef.current) {
      if (features.length > 0 && selectedFeature == null) {
        const vectorSource = new VectorSource({
          features: features,
        });
        mapRef.current.getView().fit(vectorSource.getExtent(), {
          size: mapRef.current.getSize(),
          duration: 200,
          maxZoom: 18
        });
      }
    }
  }, [features]);

  useEffect(() => {
    if (selectRef.current) {
      const mapSelectedFeature = selectRef.current.getFeatures();
      if (selectedFeature) {
        const currentSelectedFeatures = mapSelectedFeature.getArray();
        if (currentSelectedFeatures.length === 0 || currentSelectedFeatures[0].getProperties().id_parcelle !== selectedFeature.getProperties().id_parcelle) {
          mapSelectedFeature.clear();
          mapSelectedFeature.push(selectedFeature);

          const extent = selectedFeature.getGeometry().getExtent();

          mapRef.current.getView().fit(extent, {
            padding: isMobile ? [50, 50, 200, 50] : [150, 150, 250, 150],
            maxZoom: 19,
            duration: 200,
          });
          const style = selectedFeature.getStyle()
          style.setZIndex(100);
          selectedFeature.setStyle(style);
        }
      }
    }
  }, [selectedFeature]);


  const colors = ["#F72585", "#F72585", "#3A0CA3", "#4361EE", "#4361EE", "#4CC9F0", "#ff9500", "#ff8fa3", "#0e6ba8"];

  const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)] + "10";
  }

  const [colorTL] = useState(() => getRandomColor());
  const [colorTR] = useState(() => getRandomColor());
  const [colorM] = useState(() => getRandomColor());
  const [colorBR] = useState(() => getRandomColor());
  const [colorBL] = useState(() => getRandomColor());
  return (
    <Box sx={{
      position: "absolute",
      top: 0,
      left: 0,
      height: "100%",
      width: "100%"
    }}>
      <Popover
        open={Boolean(anchorPosition)}
        onClose={() => setAnchorPosition(null)}
        anchorReference="anchorPosition"
        anchorPosition={anchorPosition ? {
          top: anchorPosition.top,
          left: anchorPosition.left
        } : undefined}
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box sx={{
          display: "flex",
          flexDirection: "row",
          padding: "5px 10px",
          gap: "5px"
        }}>
          <GoogleMapsButton
            sw={{}}
            longitude={lonLat && lonLat[0]}
            latitude={lonLat && lonLat[1]}
          />
          <GoogleStreetViewButton
            sw={{}}
            longitude={lonLat && lonLat[0]}
            latitude={lonLat && lonLat[1]}
          />
        </Box>
      </Popover>

      <Box ref={mapDivRef} style={{
        filter: "contrast(120%)",
        width: "100%",
        height: "100%"
      }}/>
      {!resultPanelOpen && <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backdropFilter: "blur(3px)",
          filter: "grayscale(0.3) contrast(150%)",
          background: "linear-gradient(140deg, " + colorTL + " 0%, " + colorM + " 50%, " + colorTR + " 100%), linear-gradient(240deg," + colorBL + " 0%, " + colorM + " 50%, " + colorBR + " 100%);"
        }}
      />}
    </Box>
  );
};

export default Map;
