import React from "react";
import Box from "@mui/material/Box";
import {useTheme} from "@mui/material/styles";
import Button from "@mui/material/Button";
import useMediaQuery from "@mui/material/useMediaQuery";

const GoogleMapsButton = ({
                            sx,
                            longitude,
                            latitude
                          }) => {
  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const google_maps_link = `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}&basemap=satellite&zoom=18.8`
  return <Button
    target="_blank"
    href={google_maps_link}
    sx={{
      ...sx,
      backgroundColor: "primary.contrastText",
      height: "28px",
      minWidth: "28px",
      padding: "0px",
      borderRadius: "20px",
      color: "primary.contrastText",
      "&:hover": {
        backgroundColor: theme.palette.primary.light,
      },
      "&:active": {
        backgroundColor: theme.palette.primary.main,
      }
    }}
  >
    <Box
      className="backgroundimage-google-maps"
      sx={{
        position: "relative",
        width: "20px",
        height: "20px",
      }}
    />
  </Button>
};

export default GoogleMapsButton;
