import React, {useContext} from "react";
import Box from "@mui/material/Box";
import {SearchContext, TYPE_RECHERCHE_ADRESSE, TYPE_RECHERCHE_REFERENCECADASTRALE, TYPE_RECHERCHE_SURFACE} from "../context/SearchProvider";
import CommuneSelect from "./CommuneSelect";
import SurfaceField from "./SurfaceField";
import AdresseSelect from "./AdresseSelect";
import ParcelleSelect from "./ParcelleSelect";
import {useTheme} from "@mui/material/styles";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import useMediaQuery from "@mui/material/useMediaQuery";


const SearchPanel = ({
                       hideTenterRegrouperParcelles = false,
                       sx,
                       size = "normal"
                     }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const isLight = theme.palette.mode === "light";

  const {
    typeRecherche,
    idCommune,
    setIdCommune,
    distanceKmAutourCommune,
    setDistanceKmAutourCommune,
    surface,
    setSurface,
    toleranceSurfacePourcentage,
    setToleranceSurfacePourcentage,
    tenterRegrouperParcelles,
    setTenterRegrouperParcelles,
    setIdAdresse,
    distanceMAutourAdresse,
    setDistanceMAutourAdresse,
    setIdParcelle,
  } = useContext(SearchContext);

  return <Box
    sx={{
      height: "220px",
      ...sx,
      display: "flex",
      flexDirection: "column",
      alignItems: "stretch",
      justifyContent: "flex-start",
      gap: "20px",
      padding: "20px 40px",
      [theme.breakpoints.down("sm")]: {
        padding: "30px 20px",
      }

    }}>
    {typeRecherche === TYPE_RECHERCHE_SURFACE && (<>
      <CommuneSelect
        fieldProps={{
          sx: {},
          autoFocus: true
        }}
        size={size}
        onSelectCommune={(commune) => setIdCommune(commune.id_commune)}
        distanceKmAutourCommune={distanceKmAutourCommune}
        onSelectDistanceKmAutourCommune={(distanceKmAutourCommune) => setDistanceKmAutourCommune(distanceKmAutourCommune)}
      />
      <SurfaceField
        fieldProps={{
          sx: {
            width: (size === "small" ? "80%" : "65%"),
            [theme.breakpoints.down("sm")]: {
              width: "80%",
            }
          }
        }}
        size={size}
        surface={surface}
        onSetSurface={setSurface}
        toleranceSurfacePourcentage={toleranceSurfacePourcentage}
        onSelectToleranceSurfacePourcentage={(toleranceSurfacePourcentage) => setToleranceSurfacePourcentage(toleranceSurfacePourcentage)}
      />
      {!hideTenterRegrouperParcelles && <FormControlLabel
        label="Détecter les parcelles potentiellement divisées"
        sx={{
          padding: "0 10px",
        }}
        slotProps={{
          typography: {
            fontSize: "80%",
          },
        }}
        control={
          <Switch
            size="small"
            checked={tenterRegrouperParcelles}
            onChange={((event) => {
              setTenterRegrouperParcelles(event.target.checked);
            })}
          />
        }
      />}
    </>)}
    {typeRecherche === TYPE_RECHERCHE_ADRESSE && (<>
      <AdresseSelect
        fieldProps={{
          sx: {},
          autoFocus: true
        }}
        size={size}
        onSelectAdresse={(adresse) => setIdAdresse(adresse.id_adresse)}
        distanceMAutourAdresse={distanceMAutourAdresse}
        onSelectDistanceMAutourAdresse={(distanceMAutourAdresse) => setDistanceMAutourAdresse(distanceMAutourAdresse)}
      />
    </>)}
    {typeRecherche === TYPE_RECHERCHE_REFERENCECADASTRALE && (<>
      <CommuneSelect
        fieldProps={{
          sx: {},
          autoFocus: true
        }}
        size={size}
        onSelectCommune={(commune) => setIdCommune(commune.id_commune)}
        hideDistance={true}
      />
      <ParcelleSelect
        disabled={idCommune === undefined || idCommune === null}
        idCommune={idCommune}
        fieldProps={{
          sx: {
            "& .MuiInputLabel-root": {
              paddingLeft: "20px",
              transition: "all 0.3s ease",
              color: isLight ? "rgba(0, 0, 0, 0.38)" : "rgba(255, 255, 255, 0.5)"
            },
            "& .MuiInputLabel-root.MuiInputLabel-shrink": {
              paddingLeft: "0",
            },
          },
        }}
        size={size}
        onSelectParcelle={(parcelle) => setIdParcelle(parcelle ? parcelle.id_parcelle : null)}
      />
    </>)}
  </Box>;

};

export default SearchPanel;
