import React, {useContext} from "react";
import Map from "./App/Map";
import UpdatingPopup from "./App/UpdatingPopup";
import SearchPopup from "./App/SearchPopup";
import Bottom from "./App/Bottom";
import ResultPanel from "./App/ResultPanel";
import MentionsLegalesPopup from "./App/MentionsLegalesPopup";
import {AppContext} from "./App/context/AppProvider";
import Alert from "@mui/material/Alert";


const App = () => {
  const {
    updatingPopupOpen,
    searchPopupOpen,
    resultPanelOpen,
    mentionsLegalesOpen,
    alertMessage
  } = useContext(AppContext);

  return <>
    <Map/>
    {updatingPopupOpen && <UpdatingPopup/>}
    {searchPopupOpen && <SearchPopup/>}
    {resultPanelOpen && <ResultPanel/>}
    {mentionsLegalesOpen && <MentionsLegalesPopup/>}
    <Bottom
      style={{
        position: "absolute",
        bottom: "0px",
        right: 0,
      }}
    />
    {alertMessage && (
      <Alert severity={"error"} sx={{
        position: "absolute",
        top: "20px",
        right: "20px",
        width: "300px"
      }}>
        {alertMessage}
      </Alert>
    )}
  </>
};

export default App;
