import React, {createContext, useState} from "react";
import axios from "axios";

export const AppContext = createContext();

const AppProvider = ({children}) => {
  const [cadastreVersionDate] = useState(data.cadastreversiondate);
  const [loading, setLoading] = useState(false);
  const [updatingPopupOpen, setUpdatingPopupOpen] = useState(data.status === "UPDATING");
  const [searchPopupOpen, setSearchPopupOpen] = useState(data.status === "OK");
  const [resultPanelOpen, setResultPanelOpen] = useState(false);
  const [mentionsLegalesOpen, setMentionsLegalesOpen] = useState(window.location.hash === '#cgu' || window.location.hash === '#mentions-legales');
  const [needToWaitUntil, setNeedToWaitUntil] = useState();
  const [progressToWait, setProgressToWait] = useState(null);
  const [alertMessage, setAlertMessage] = useState(null);


  const openUpdatingPopup = () => {
    setUpdatingPopupOpen(true);
    setSearchPopupOpen(false);
    setResultPanelOpen(false);
  };
  const openSearchPopup = () => {
    setUpdatingPopupOpen(false);
    setSearchPopupOpen(true);
    setResultPanelOpen(false);
  };
  const openResultPanel = () => {
    setUpdatingPopupOpen(false);
    setSearchPopupOpen(false);
    setResultPanelOpen(true);
  };

  const getWaitTokenAndWait = async () => {
    try {
      const responseData = await axios.get("/get_wait_token");
      const tokenData = responseData.data.token;
      const token = JSON.parse(tokenData) || [];

      const dateToWait = new Date(token.validity_date);
      const startTime = new Date();
      const timeToWait = debug ? 0 : (dateToWait.getTime() - startTime.getTime());

      if (timeToWait <= 0) {
        return Promise.resolve(token.id);
      }

      const intervalId = setInterval(() => {
        const currentTime = new Date().getTime();
        const elapsedTime = currentTime - startTime;
        const percentage = Math.min((elapsedTime / timeToWait) * 100, 100);

        setProgressToWait(percentage);
        setNeedToWaitUntil(Math.round((timeToWait - elapsedTime) / 1000));

        if (percentage >= 100) {
          setNeedToWaitUntil(null);
          setProgressToWait(null);
          clearInterval(intervalId);
        }
      }, 100);

      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(token.id);
        }, timeToWait);
      });
    } catch(error) {
      console.error("Error fetching data", error);
    }
  };

  return <AppContext.Provider value={{
    cadastreVersionDate,
    loading,
    setLoading,
    updatingPopupOpen,
    openUpdatingPopup,
    searchPopupOpen,
    openSearchPopup,
    resultPanelOpen,
    openResultPanel,
    mentionsLegalesOpen,
    setMentionsLegalesOpen,
    needToWaitUntil,
    setNeedToWaitUntil,
    getWaitTokenAndWait,
    progressToWait,
    setProgressToWait,
    alertMessage,
    setAlertMessage
  }}>
    {children}
  </AppContext.Provider>
};

export default AppProvider;
